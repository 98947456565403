import React, { useState, useEffect, useRef } from 'react';

const MouseTracker = () => {
    const cursorRef = useRef(null);
    const cursorDotRef = useRef(null);
    const [isClicking, setIsClicking] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [colorIndex, setColorIndex] = useState(0);

    const colors = [
        { bg: 'bg-red-500/30', border: 'border-red-500' },
        { bg: 'bg-blue-500/30', border: 'border-blue-500' },
        { bg: 'bg-green-500/30', border: 'border-green-500' },
        { bg: 'bg-purple-500/30', border: 'border-purple-500' },
        { bg: 'bg-yellow-500/30', border: 'border-yellow-500' },
        { bg: 'bg-pink-500/30', border: 'border-pink-500' },
    ];

    useEffect(() => {
        let rafId = null;
        let mouseX = 0;
        let mouseY = 0;
        let currentX = 0;
        let currentY = 0;

        const cursor = cursorRef.current;
        const cursorDot = cursorDotRef.current;

        const onMouseMove = (e) => {
            mouseX = e.clientX;
            mouseY = e.clientY;

            // Change color based on movement distance
            const distance = Math.sqrt(
                Math.pow(mouseX - currentX, 2) + Math.pow(mouseY - currentY, 2)
            );

            if (distance > 100) {
                setColorIndex(prev => (prev + 1) % colors.length);
                currentX = mouseX;
                currentY = mouseY;
            }

            // Update dot position immediately
            if (cursorDot) {
                cursorDot.style.transform = `translate(${mouseX}px, ${mouseY}px)`;
            }
        };

        const updateCursor = () => {
            if (!cursor || !isVisible) return;

            // Smooth follow effect for outer ring
            const deltaX = mouseX - currentX;
            const deltaY = mouseY - currentY;

            currentX += deltaX * 0.15;
            currentY += deltaY * 0.15;

            cursor.style.transform = `translate(${currentX}px, ${currentY}px)`;

            rafId = requestAnimationFrame(updateCursor);
        };

        // Add event listeners with passive option for better performance
        window.addEventListener('mousemove', onMouseMove, { passive: true });
        rafId = requestAnimationFrame(updateCursor);

        const onMouseDown = () => setIsClicking(true);
        const onMouseUp = () => setIsClicking(false);

        window.addEventListener('mousedown', onMouseDown, { passive: true });
        window.addEventListener('mouseup', onMouseUp, { passive: true });

        return () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mousedown', onMouseDown);
            window.removeEventListener('mouseup', onMouseUp);
            if (rafId) cancelAnimationFrame(rafId);
        };
    }, [isVisible, colors.length]);
    return (
        <div className="w-full h-full">
            {isVisible && (
                <>
                    {/* Outer ring - smooth follow */}
                    <div
                        ref={cursorRef}
                        className={`fixed pointer-events-none z-50 top-0 left-0 -ml-5 -mt-5 w-10 h-10 
              ${colors[colorIndex].border} border-2 rounded-full 
              transition-all duration-200 ease-out will-change-transform
              ${isClicking ? 'scale-75' : 'scale-100'}`}
                    >
                        <div className={`absolute inset-0 rounded-full ${colors[colorIndex].bg} 
              transition-colors duration-200 ease-out opacity-50`}
                        />
                    </div>

                    {/* Inner dot - instant follow */}
                    <div
                        ref={cursorDotRef}
                        className={`fixed pointer-events-none z-50 top-0 left-0 
              -translate-x-1/2 -translate-y-1/2 w-2 h-2
              transition-all duration-150 ease-out will-change-transform
              rounded-full ${colors[colorIndex].bg} 
              ${isClicking ? 'scale-150' : 'scale-100'}`}
                    />
                </>
            )}
        </div>
    );
};

export default MouseTracker;