import React, { useState, useEffect } from 'react';
import { Github, Linkedin, Instagram, Twitter, Facebook } from 'lucide-react';

const Home = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const social = [
    { name: "Github", link: "https://github.com/rudalkunwar/", icon: Github, color: "#333" },
    { name: "LinkedIn", link: "https://np.linkedin.com/in/rudal-kunwar-4561bb260", icon: Linkedin, color: "#0077B5" },
    { name: "Instagram", link: "#", icon: Instagram, color: "#E4405F" },
    { name: "Twitter", link: "#", icon: Twitter, color: "#1DA1F2" },
    { name: "Facebook", link: "https://www.facebook.com/kuns.ruzal", icon: Facebook, color: "#1877F2" }
  ];

  return (
    <section id="home" className="relative z-20 min-h-screen w-full overflow-hidden">
      <div className="relative min-h-screen flex items-center justify-center px-6">
        <div className={`max-w-7xl w-full mx-auto grid grid-cols-1 lg:grid-cols-2 gap-16 items-center transition-all duration-1000 ${isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
          {/* Text Content */}
          <div className={`space-y-10 text-center lg:text-left ${isLoaded ? 'animate-fade-in' : 'opacity-0'}`}>
            <div className="space-y-6">
              <div className="inline-flex items-center space-x-2">
                <div className="h-[2px] w-8 bg-emerald-500" />
                <h2 className="text-emerald-400 text-xl font-medium tracking-wider glitch-text">Hello, I'm</h2>
              </div>
              <div className="relative">
                <h1 className="text-6xl lg:text-7xl font-bold text-white tracking-tight main-heading">Rudal Kunwar</h1>
                <div className="absolute -inset-2 bg-emerald-500/20 blur-lg opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
              </div>
              <div className="relative inline-block">
                <p className="text-2xl text-gray-300 font-medium cyberpunk-text">Fullstack Developer</p>
                <div className="absolute -inset-1 bg-gradient-to-r from-purple-500/20 to-emerald-500/20 blur-sm" />
              </div>
            </div>

            {/* Social Icons */}
            <div className="flex flex-wrap justify-center lg:justify-start gap-5">
              {social.map((item) => (
                <a key={item.name} href={item.link} target="_blank" rel="noopener noreferrer" className="group perspective">
                  <div className="relative transform-gpu transition-all duration-500 preserve-3d hover:rotate-y-180 hover:scale-110">
                    <div className="p-4 bg-gray-800/30 rounded-xl backdrop-blur-md border border-gray-700/50 shadow-lg shadow-black/20">
                      <item.icon size={24} className="transition-colors duration-300 text-gray-400 group-hover:text-emerald-400" />
                    </div>
                    <div className="absolute inset-0 p-4 bg-emerald-500/10 rounded-xl backdrop-blur-md border border-emerald-500/30 transform-gpu rotate-y-180 backface-hidden">
                      <item.icon size={24} className="text-emerald-400" />
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>

          {/* Profile Image */}
          <div className={`relative flex justify-center lg:justify-end perspective ${isLoaded ? 'animate-fade-in' : 'opacity-0'}`}>
            <div className={`relative w-80 h-80 lg:w-96 lg:h-96 transform-gpu transition-transform duration-700 ${isLoaded ? 'scale-100' : 'scale-90'} clip-star`}>
              {/* Glitch Effect Layers */}
              <div className="absolute inset-0 glitch-layer-1" />
              <div className="absolute inset-0 glitch-layer-2" />

              {/* Main Image Container */}
              <div className="relative w-full h-full clip-star overflow-hidden rounded-full">
                <div className="absolute inset-0 bg-gradient-to-b from-emerald-500/30 to-purple-500/30 mix-blend-overlay" />
                <div className="absolute inset-0 border-4 border-emerald-500/30 glow-border" />
                <img src="/images/image.png" alt="Profile" className="w-full h-full object-cover filter brightness-110 rounded-full shadow-lg transition-transform duration-300 hover:scale-105" />
              </div>

              {/* Orbiting Elements */}
              <div className="absolute inset-[-2rem] border-2 border-dashed border-emerald-500/20 animate-spin-slow" />
              <div className="absolute inset-[-1rem] border-2 border-dashed border-purple-500/20 animate-spin-slow-reverse" />
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .perspective {
          perspective: 1000px;
        }

        .preserve-3d {
          transform-style: preserve-3d;
        }

        .backface-hidden {
          backface-visibility: hidden;
        }

        .rotate-y-180 {
          transform: rotateY(180deg);
        }

        .main-heading {
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3), -2px -2px 4px rgba(255, 255, 255, 0.1);
        }

        .glow-border {
          animation: borderGlow 4s ease-in-out infinite;
        }

        @keyframes borderGlow {
          0%, 100% { box-shadow: 0 0 20px rgba(16, 185, 129, 0.2); }
          50% { box-shadow: 0 0 40px rgba(16, 185, 129, 0.4); }
        }

        .animate-spin-slow {
          animation: spin 20s linear infinite;
        }

        .animate-spin-slow-reverse {
          animation: spin 15s linear infinite reverse;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </section>
  );
};

export default Home;
