import React from "react";

const About = () => {
  return (
    <>
      <section
        id="about"
        className="pb-28 pt-4 relative z-10"
      >
        <h2 className="text-4xl md:text-6xl font-bold text-white mb-8 text-center">
          ABOUT
        </h2>
        <div className="justify-center flex-1 max-w-6xl py-4 mx-auto lg:py-6 md:px-6">
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 mb-10 md:mb-0 md:pr-4">
              <div className="relative">
                <img
                  src="images/rudalkunwar.png"
                  alt="Rudal Kunwar"
                  className="relative z-40 object-cover w-full h-96 rounded-lg md:rounded-tr-[80px] md:rounded-bl-[80px] md:px-4 md:py-4"
                />
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="relative">
                <h1 className="pl-2 text-3xl md:text-4xl font-bold border-l-8 border-green-500 mb-6 px-4 py-2 text-white">
                  Hi, I'm Rudal Kunwar
                </h1>
                <p className="mt-2 mb-6 text-lg leading-7 text-gray-300 px-4">
                  Highly motivated full-stack developer with a passion for creating dynamic, user-friendly web applications. My
                  work is driven by a commitment to delivering scalable, high-performance solutions. With expertise in both
                  frontend and backend, I excel in responsive design, API integration, and database management, always striving
                  to improve user experience and system efficiency.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="absolute inset-x-0 top-0 h-px bg-gradient-to-r from-transparent via-cyan-500 to-transparent opacity-30"></div>
      </section>
    </>
  );
};

export default About;