import React, { useEffect, useRef } from 'react';

const SmoothParticles = () => {
    const canvasRef = useRef(null);
    const mouseRef = useRef({ x: 0, y: 0 });

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const setCanvasSize = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        };

        setCanvasSize();
        window.addEventListener('resize', setCanvasSize);

        // Handle mouse movement
        const handleMouseMove = (e) => {
            mouseRef.current = {
                x: e.clientX,
                y: e.clientY
            };
        };
        window.addEventListener('mousemove', handleMouseMove);

        class Particle {
            constructor() {
                this.reset();
                this.x = Math.random() * canvas.width;
                this.y = Math.random() * canvas.height;
            }

            reset() {
                // Base position
                this.x = Math.random() * canvas.width;
                this.y = Math.random() * canvas.height;

                // Velocity
                this.vx = Math.random() * 0.8 - 0.4;
                this.vy = Math.random() * 0.8 - 0.4;

                // Size
                this.radius = Math.random() * 2 + 1;

                // Color properties
                this.baseHue = Math.random() * 360;
                this.hue = this.baseHue;
                this.saturation = 80;
                this.lightness = 60;

                // Animation properties
                this.pulse = Math.random() * 0.1;
                this.pulseSpeed = 0.01;
            }

            draw() {
                // Update position with smooth movement
                this.x += this.vx;
                this.y += this.vy;

                // Bounce off edges with damping
                if (this.x < 0 || this.x > canvas.width) {
                    this.vx *= -0.8;
                }
                if (this.y < 0 || this.y > canvas.height) {
                    this.vy *= -0.8;
                }

                // Keep particles in bounds
                this.x = Math.max(0, Math.min(this.x, canvas.width));
                this.y = Math.max(0, Math.min(this.y, canvas.height));

                // Mouse interaction
                const dx = mouseRef.current.x - this.x;
                const dy = mouseRef.current.y - this.y;
                const distance = Math.sqrt(dx * dx + dy * dy);

                if (distance < 150) {
                    const angle = Math.atan2(dy, dx);
                    const force = (150 - distance) / 150;
                    this.vx -= Math.cos(angle) * force * 0.2;
                    this.vy -= Math.sin(angle) * force * 0.2;
                }

                // Pulsing effect
                this.pulse += this.pulseSpeed;
                const pulseFactor = Math.sin(this.pulse) * 0.5 + 0.5;
                const currentRadius = this.radius * (1 + pulseFactor * 0.3);

                // Color animation
                this.hue = this.baseHue + pulseFactor * 30;

                // Draw particle
                ctx.beginPath();
                ctx.arc(this.x, this.y, currentRadius, 0, Math.PI * 2);
                ctx.fillStyle = `hsla(${this.hue}, ${this.saturation}%, ${this.lightness}%, 0.8)`;
                ctx.fill();

                // Add glow effect
                const gradient = ctx.createRadialGradient(
                    this.x, this.y, 0,
                    this.x, this.y, currentRadius * 2
                );
                gradient.addColorStop(0, `hsla(${this.hue}, ${this.saturation}%, ${this.lightness}%, 0.3)`);
                gradient.addColorStop(1, 'transparent');

                ctx.beginPath();
                ctx.arc(this.x, this.y, currentRadius * 2, 0, Math.PI * 2);
                ctx.fillStyle = gradient;
                ctx.fill();
            }
        }

        // Create particles
        const particles = Array.from({ length: 80 }, () => new Particle());

        const drawConnections = () => {
            particles.forEach((p1, i) => {
                particles.slice(i + 1).forEach(p2 => {
                    const dx = p1.x - p2.x;
                    const dy = p1.y - p2.y;
                    const distance = Math.sqrt(dx * dx + dy * dy);

                    if (distance < 100) {
                        ctx.beginPath();
                        const opacity = (1 - distance / 100) * 0.5;
                        ctx.strokeStyle = `hsla(${(p1.hue + p2.hue) / 2}, 70%, 60%, ${opacity})`;
                        ctx.lineWidth = 0.5;
                        ctx.moveTo(p1.x, p1.y);
                        ctx.lineTo(p2.x, p2.y);
                        ctx.stroke();
                    }
                });
            });
        };

        const animate = () => {
            // Create subtle trail effect
            ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Update and draw particles
            particles.forEach(particle => particle.draw());
            drawConnections();

            // Apply velocity damping
            particles.forEach(particle => {
                particle.vx *= 0.99;
                particle.vy *= 0.99;
            });

            requestAnimationFrame(animate);
        };

        // Set black background
        ctx.fillStyle = 'black';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        animate();

        return () => {
            window.removeEventListener('resize', setCanvasSize);
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <canvas
            ref={canvasRef}
            className="fixed top-0 left-0 w-full h-full bg-black"
        />
    );
};

export default SmoothParticles;