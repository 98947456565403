import React from "react";
import { RiHtml5Fill, RiCss3Fill, RiJavascriptFill } from "react-icons/ri";
import { FaLaravel, FaPhp, FaWordpress } from "react-icons/fa";
import { SiMysql, SiTailwindcss } from "react-icons/si";
import MERN from "./MERN";
import ParticlesBackground from "./ui/Background";

const SkillCard = ({ skill, index }) => {
  // Custom colors for each technology
  const colors = {
    HTML: "from-orange-500/20 to-red-500/20 hover:from-orange-500/30 hover:to-red-500/30",
    CSS: "from-blue-500/20 to-cyan-500/20 hover:from-blue-500/30 hover:to-cyan-500/30",
    JavaScript: "from-yellow-500/20 to-amber-500/20 hover:from-yellow-500/30 hover:to-amber-500/30",
    PHP: "from-indigo-500/20 to-purple-500/20 hover:from-indigo-500/30 hover:to-purple-500/30",
    Laravel: "from-red-500/20 to-pink-500/20 hover:from-red-500/30 hover:to-pink-500/30",
    "MERN Stack": "from-green-500/20 to-emerald-500/20 hover:from-green-500/30 hover:to-emerald-500/30",
    "Tailwind CSS": "from-cyan-500/20 to-blue-500/20 hover:from-cyan-500/30 hover:to-blue-500/30",
    MySQL: "from-blue-500/20 to-indigo-500/20 hover:from-blue-500/30 hover:to-indigo-500/30",
    WordPress: "from-blue-400/20 to-cyan-500/20 hover:from-blue-400/30 hover:to-cyan-500/30",
  };

  return (
    <div
      className={`relative group backdrop-blur-md rounded-2xl p-6 
                  transform hover:scale-105 transition-all duration-500 ease-out
                  border border-white/10 hover:border-white/20
                  bg-gradient-to-br ${colors[skill.title]}`}
    >
      {/* Glowing effect on hover */}
      <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-white/5 to-white/0 opacity-0 
                    group-hover:opacity-100 transition-opacity duration-500" />

      {/* Content */}
      <div className="relative z-10">
        <div className="mb-6 transform transition-transform duration-500 group-hover:scale-110 
                      flex justify-center text-white/80 group-hover:text-white">
          {skill.icon}
        </div>

        <h3 className="text-xl font-bold mb-3 text-white tracking-wide">
          {skill.title}
        </h3>

        <p className="text-gray-300 text-sm leading-relaxed opacity-80 group-hover:opacity-100 
                     transition-opacity duration-500">
          {skill.description}
        </p>
      </div>
    </div>
  );
};

const Skills = () => {
  const skills = [
    {
      title: "HTML",
      description: "Hypertext Markup Language is the standard markup language for documents designed to be displayed in a web browser.",
      icon: <RiHtml5Fill size={48} className="text-orange-500" />,
    },
    {
      title: "CSS",
      description: "Cascading Style Sheets is a style sheet language used for describing the presentation of a document written in HTML.",
      icon: <RiCss3Fill size={48} className="text-blue-500" />,
    },
    {
      title: "JavaScript",
      description: "JavaScript is a programming language that enables interactive web pages and is an essential part of web development.",
      icon: <RiJavascriptFill size={48} className="text-yellow-400" />,
    },
    {
      title: "PHP",
      description: "PHP is a server-side scripting language designed for web development but also used as a general-purpose programming language.",
      icon: <FaPhp size={48} className="text-indigo-500" />,
    },
    {
      title: "Laravel",
      description: "Laravel is a PHP framework for web artisans, providing expressive and elegant syntax while building web applications.",
      icon: <FaLaravel size={48} className="text-red-500" />,
    },
    {
      title: "MERN Stack",
      description: "The MERN stack is a JavaScript stack used for building full-stack web applications, consisting of MongoDB, Express.js, React.js, and Node.js.",
      icon: <MERN />,
    },
    {
      title: "Tailwind CSS",
      description: "Tailwind CSS is a utility-first CSS framework.",
      icon: <SiTailwindcss size={48} className="text-cyan-400" />,
    },
    {
      title: "MySQL",
      description: "MySQL is an open-source relational database management system.",
      icon: <SiMysql size={48} className="text-blue-500" />,
    },
    {
      title: "WordPress",
      description: "WordPress is a free and open-source content management system.",
      icon: <FaWordpress size={48} className="text-blue-400" />,
    },
  ];

  return (
    <section className="relative pb-16" id="skills">
      {/* Background gradients */}
      <div className="absolute inset-0 bg-gradient-to-b from-black/0 via-black/50 to-black/0 pointer-events-none" />

      <div className="container mx-auto px-4 relative z-10">
        {/* Section Header */}
        <div class="absolute inset-x-0 top-0 h-px bg-gradient-to-r from-transparent via-cyan-500 to-transparent opacity-30"></div>
        <div className="text-center mb-16 pt-4">
          <h2 className="text-4xl md:text-6xl font-bold text-white mb-4 tracking-tight">
            Technical Skills
          </h2>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            A comprehensive collection of technologies I work with to create modern web solutions.
          </p>
        </div>

        {/* Skills Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8 max-w-7xl mx-auto">
          {skills.map((skill, index) => (
            <SkillCard key={index} skill={skill} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;