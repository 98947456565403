import React from "react";
import Header from "./components/partials/Header";
import Footer from "./components/partials/Footer";
import Home from "./components/Home";
import Skills from "./components/Skills";
import About from "./components/About";
import Contact from "./components/Contact";
import GoToTop from "./components/GoTop";
import Project from "./components/Project";
import SmoothParticles from "./components/ui/Background";
import { MouseProvider } from "./context/MouseContext";
import MouseTracker from "./components/MouseTracker";

function App() {
  return (
    <div>
      <MouseProvider>
        <Header />
        <SmoothParticles />
        <MouseTracker />
        <>
          <Home />
          <About />
          <Skills />
          <Project />
          <Contact />
          <Footer />
        </>
        <GoToTop />
      </MouseProvider>
    </div>
  );
}

export default App;
