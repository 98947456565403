import React from "react";
import {
  BsFacebook,
  BsGithub,
  BsInstagram,
  BsTwitter,
  BsLinkedin,
} from "react-icons/bs";
import { Link } from "react-router-dom";

const social = [
  {
    name: "Github",
    link: "https://github.com/rudalkunwar/",
    icon: <BsGithub size={24} />,
    hoverColor: "hover:text-purple-400"
  },
  {
    name: "LinkedIn",
    link: "https://np.linkedin.com/in/rudal-kunwar-4561bb260",
    icon: <BsLinkedin size={24} />,
    hoverColor: "hover:text-blue-400"
  },
  {
    name: "Instagram",
    link: "#",
    icon: <BsInstagram size={24} />,
    hoverColor: "hover:text-pink-400"
  },
  {
    name: "Twitter",
    link: "#",
    icon: <BsTwitter size={24} />,
    hoverColor: "hover:text-sky-400"
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/kuns.ruzal",
    icon: <BsFacebook size={24} />,
    hoverColor: "hover:text-blue-500"
  }
];

const technologies = [
  {
    name: "React",
    link: "https://react.dev/",
    color: "text-cyan-400"
  },
  {
    name: "Tailwind CSS",
    link: "https://tailwindcss.com",
    color: "text-teal-400"
  }
];

function Footer() {
  return (
    <footer className="relative w-full mt-auto">
      {/* Glowing border effect */}
      <div className="absolute inset-x-0 top-0 h-px bg-gradient-to-r from-transparent via-cyan-500 to-transparent opacity-50" />

      {/* Glass background */}
      <div className="relative z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
            {/* Technology Section */}
            <div className="space-y-4">
              <h2 className="text-xl font-semibold text-white tracking-wide">
                Technology Used
              </h2>
              <ul className="space-y-2">
                {technologies.map((tech, index) => (
                  <li key={index} className="transform transition-transform hover:translate-x-2">
                    <Link
                      to={tech.link}
                      className={`${tech.color} hover:brightness-125 transition-all duration-300`}
                    >
                      {tech.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Social Links Section */}
            <div className="space-y-4">
              <h2 className="text-xl font-semibold text-white tracking-wide">
                Connect With Me
              </h2>
              <ul className="space-y-3">
                {social.map((soc, index) => (
                  <li key={index} className="transform transition-transform hover:translate-x-2">
                    <Link
                      target="_blank"
                      to={soc.link}
                      className={`flex items-center space-x-3 text-gray-300 ${soc.hoverColor} transition-colors duration-300`}
                    >
                      <span className="transition-transform duration-300 group-hover:scale-110">
                        {soc.icon}
                      </span>
                      <span className="text-sm">{soc.name}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Copyright Section */}
            <div className="space-y-4">
              <h2 className="text-xl font-semibold text-white tracking-wide">
                About
              </h2>
              <p className="text-gray-400 text-sm leading-relaxed">
                Creating digital experiences with modern web technologies.
                Let's build something amazing together.
              </p>
            </div>
          </div>

          {/* Bottom Bar */}
          <div className="mt-12 pt-8 border-t border-gray-800">
            <div className="flex flex-col items-center sm:flex-row sm:justify-between">
              <p className="text-gray-400 text-sm">
                © {new Date().getFullYear()} Rudalkunwar. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;