import React from "react";
import { FaGithub, FaLink } from "react-icons/fa";
import ParticlesBackground from "./ui/Background"; // Assuming this is your particles background component

const ProjectCard = ({ project }) => {
    // Custom colors for each project
    const colors = {
        Nflix: "from-green-500/20 to-blue-500/20 hover:from-green-500/30 hover:to-blue-500/30",
        "Online Bookstore": "from-purple-500/20 to-pink-500/20 hover:from-purple-500/30 hover:to-pink-500/30",
        "Recipe App": "from-indigo-500/20 to-blue-600/20 hover:from-indigo-500/30 hover:to-blue-600/30",
        "LaunchBox": "from-yellow-500/20 to-orange-500/20 hover:from-yellow-500/30 hover:to-orange-500/30",
        "Garbage Complaint System": "from-blue-400/20 to-blue-600/20 hover:from-blue-400/30 hover:to-blue-600/30",
        "Weather App": "from-cyan-500/20 to-blue-500/20 hover:from-cyan-500/30 hover:to-blue-500/30",
        // Add more projects here as needed
    };

    return (
        <div
            className={`relative group backdrop-blur-md rounded-2xl p-6 
                transform hover:scale-105 transition-all duration-500 ease-out
                border border-white/10 hover:border-white/20
                bg-gradient-to-br ${colors[project.title]}`}
        >
            {/* Glowing effect on hover */}
            <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-white/5 to-white/0 opacity-0 
                group-hover:opacity-100 transition-opacity duration-500" />

            {/* Content */}
            <div className="relative z-10">
                <h3 className="text-xl font-bold mb-3 text-white tracking-wide">
                    {project.title}
                </h3>

                <p className="text-gray-300 text-sm leading-relaxed opacity-80 group-hover:opacity-100 
                    transition-opacity duration-500">
                    {project.description}
                </p>

                {/* Project Links */}
                <div className="flex justify-between mt-4">
                    <a
                        href={project.github}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white hover:text-blue-400 transition-colors duration-300"
                    >
                        <FaGithub size={26} />
                    </a>
                    <a
                        href={project.liveDemo}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white hover:text-blue-400 transition-colors duration-300"
                    >
                        <FaLink size={26} />
                    </a>
                </div>
            </div>
        </div>
    );
};

const Projects = () => {
    const projects = [
        {
            title: "Nflix",
            description: "A movie streaming site developed with Laravel and Vue.js, featuring user authentication, watchlist, and rating system.",
            github: "https://github.com/yourusername/nflix",
            liveDemo: "https://nflix-rust.vercel.app",
        },
        {
            title: "Online Bookstore",
            description: "An online bookstore application built with Laravel, offering user authentication, book management, and payment integration.",
            github: "https://github.com/rudalkunwar/online-books-store",
            liveDemo: "#",
        },
        {
            title: "Recipe App",
            description: "A React-based application where you can explore, search, and save delicious recipes from various cuisines using Spoonacular API.",
            github: "https://github.com/rudalkunwar/recipe-app",
            liveDemo: "https://recipe-app-lilac-five.vercel.app/",
        },
        {
            title: "LaunchBox",
            description: "A platform to manage and launch your favorite games and applications with a sleek user interface.",
            github: "https://github.com/rudalkunwar/launchbox",
            liveDemo: "#",
        },
        {
            title: "Garbage Complaint System",
            description: "An application for users to report garbage-related complaints to local authorities, developed using PHP.",
            github: "https://github.com/rudalkunwar/GarbageComplaintSystem",
            liveDemo: "#",
        },
        {
            title: "Weather App",
            description: "A weather forecasting application that provides current and future weather updates based on location.",
            github: "https://github.com/rudalkunwar/weather-app-by-Ruzal",
            liveDemo: "https://rudalkunwar.github.io/weather-app-by-Ruzal/",
        },
        // Add more projects as needed
    ];

    return (
        <section className="relative pb-16 pt-4" id="projects">
            <ParticlesBackground />

            {/* Background gradients */}
            <div className="absolute inset-0 bg-gradient-to-b from-black/0 via-black/50 to-black/0 pointer-events-none" />
            <div class="absolute inset-x-0 top-0 h-px bg-gradient-to-r from-transparent via-cyan-500 to-transparent opacity-30"></div>
            <div className="container mx-auto px-4 relative z-10">
                {/* Section Header */}
                <div className="text-center mb-16">
                    <h2 className="text-4xl md:text-6xl font-bold text-white mb-4 tracking-tight">
                        My Projects
                    </h2>
                    <p className="text-gray-400 text-lg max-w-2xl mx-auto">
                        A comprehensive collection of projects I have worked on.
                    </p>
                </div>

                {/* Projects Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8 max-w-7xl mx-auto">
                    {projects.map((project, index) => (
                        <ProjectCard key={index} project={project} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Projects;
