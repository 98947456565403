import axios from "axios";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FcGoogle } from "react-icons/fc";
import { FaSpinner } from "react-icons/fa";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { HiMail } from "react-icons/hi";
import { MdLocationOn } from "react-icons/md";

const Contact = () => {
  const [isLogged, setLogged] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);

  const formhandeller = async (e) => {
    setLoading(true);
    e.preventDefault();
    const form = e.target;
    const formdata = new FormData(form);
    const message = formdata.get("message");
    const username = userData.given_name;
    const email = userData.email;
    const emailVerified = userData.email_verified;
    const endpoint = "https://formspree.io/f/mqkrqqqq";
    
    try {
      const response = await axios.post(endpoint, {
        username,
        email,
        emailVerified,
        message,
      });
      if (response.status === 200) {
        toast.success("Message sent successfully!");
        form.reset();
        setLoading(false);
      } else {
        toast.error("Error occurred, please try again later.");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error occurred, please try again later.");
      setLoading(false);
    }
  };

  return (
    <section id="contact" className="pt-4 relative z-20">
      <ToastContainer />
      
      {/* Decorative elements */}
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/30 pointer-events-none" />
      <div className="absolute inset-x-0 top-0 h-px bg-gradient-to-r from-transparent via-cyan-500 to-transparent opacity-30" />
      
      <div className="container mx-auto px-4 md:px-6 lg:px-8 relative">
        <h2 className="text-4xl md:text-6xl font-bold text-white mb-8 text-center tracking-tight">
          Get in Touch
        </h2>
        
        <div className="flex flex-col md:flex-row gap-8 max-w-6xl mx-auto backdrop-blur-md bg-white/5 rounded-2xl p-6 shadow-2xl">
          {/* Left side - Map and Contact Info */}
          <div className="md:w-1/2 space-y-6">
            {/* Map */}
            <div className="rounded-xl overflow-hidden h-[300px] shadow-lg transform transition-transform hover:scale-[1.02]">
              <iframe
                title="Google Maps Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.383208227319!2d84.42190137497391!3d27.67454862693827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3994fb08742e4669%3A0x6c6f366373993b3!2sBharatpur%20Nepal!5e0!3m2!1sen!2snp!4v1707904454178!5m2!1sen!2snp"
                width="100%"
                height="100%"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="object-cover h-full w-full"
              />
            </div>

            {/* Contact Information */}
            <div className="space-y-4 p-4">
              <div className="flex items-center space-x-3 text-gray-200">
                <MdLocationOn className="text-cyan-400 text-xl" />
                <span>Bharatpur, Nepal</span>
              </div>
              <div className="flex items-center space-x-3 text-gray-200">
                <HiMail className="text-cyan-400 text-xl" />
                <span>ruzalkunwar@gmail.com</span>
              </div>
            </div>
          </div>

          {/* Right side - Contact Form */}
          <div className="md:w-1/2 space-y-6">
            <div className="space-y-2">
              <h3 className="text-xl text-cyan-400 font-semibold tracking-wide">
                Need Help?
              </h3>
              <h4 className="text-3xl text-white font-bold">
                Send Me A Message
              </h4>
            </div>

            <form onSubmit={formhandeller} className="space-y-6">
              <div className="relative group">
                <textarea
                  className="w-full h-48 bg-white/10 border border-gray-600 rounded-xl py-3 px-4 
                           text-white placeholder-gray-400 focus:outline-none focus:ring-2 
                           focus:ring-cyan-500 focus:border-transparent transition-all duration-300
                           resize-none"
                  placeholder="Your message here..."
                  name="message"
                  required="required"
                />
                <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-cyan-500/20 to-purple-500/20 opacity-0 
                              group-hover:opacity-100 transition-opacity duration-300 pointer-events-none" />
              </div>

              <div className="w-full">
                {!isLogged ? (
                  <div className="transform hover:scale-105 transition-transform duration-300">
                    <GoogleLogin
                      onSuccess={(credentialResponse) => {
                        const userDetails = jwtDecode(credentialResponse.credential);
                        setUserData(userDetails);
                        setLogged(true);
                      }}
                      onError={() => {
                        console.log("Login Failed");
                        toast.error("Login failed. Please try again.");
                      }}
                    />
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="w-full bg-gradient-to-r from-cyan-500 to-blue-600 text-white font-semibold 
                             py-3 px-6 rounded-xl shadow-lg hover:shadow-cyan-500/25 
                             transform hover:scale-[1.02] transition-all duration-300
                             disabled:opacity-50 disabled:cursor-not-allowed
                             flex justify-center items-center gap-2"
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="flex items-center">
                        <FaSpinner className="animate-spin mr-2" />
                        Sending...
                      </span>
                    ) : (
                      <>
                        <FcGoogle size={20} />
                        <span>Send as {userData.given_name}</span>
                      </>
                    )}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;